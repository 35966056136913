import React from 'react';
import { IntlProvider } from 'react-intl';
import { useLocale } from './contexts/LocaleContext'; // Import the useLocale hook
import { useState, useEffect } from 'react';

const messages = {
  // Define your messages for each language

  fr: {
    greeting: 'Bonjour!',
  },
};
const loadLocaleData = (locale) => {
  switch (locale) {
    case 'en':
      return import('./locales/en.json');
    case 'et':
      return import('./locales/et.json');
    default:
      return import('./locales/en.json');
  }
};

const language = navigator.language.split(/[-_]/)[0]; // Get user's preferred language

const Locales = ({ children }) => {
  const { locale } = useLocale();

  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(locale).then((d) => {
      setMessages(d.default);
    });
  }, [locale]);


  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default Locales;
