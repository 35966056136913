import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import logo from './logo.svg';
import Download from './download';
import Register from './register';
import Login from './Login';
import Home from './home';
import { LocaleProvider } from './contexts/LocaleContext';
import Locales from './Locales';
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';

import GuestGuard from './utils/route-guard/GuestGuard';
import AuthGuard from './utils/route-guard/AuthGuard';
import AdminGuard from './utils/route-guard/AdminGuard';
import EmployeeTable from "EmployeeTable";
import Employee from "Employee";

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthGuard>
        <Home />
      </AuthGuard>
    ),
  },
  {
    path: '/employees',
    element: (
      <AuthGuard>
        <EmployeeTable />
      </AuthGuard>
    ),
  },
  {
    path: '/employee/:id',
    element: (
      <AuthGuard>
        <Employee />
      </AuthGuard>
    ),
  },
  {
    path: '/download',
    element: <Download />,
  },
  {
    path: '/register',
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: '/login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
],
  { basename: '' }
);

function App() {
  return (
    <LocaleProvider>
      <Locales>
        <AuthProvider>
          <div id="root" >
            <RouterProvider router={router} />
          </div>
        </AuthProvider>
      </Locales>
    </LocaleProvider >
  );
}



export default App;
