import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useLocale } from '../contexts/LocaleContext';

export default function Locales() {
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Eesti' }
    // Add more languages as needed
  ];
  const { locale: currentLocale, changeLocale } = useLocale(); // Using the useLocale hook
  const intl = useIntl();

  const handleChangeLocale = (newValue) => {
    if (newValue) {
      intl.locale = newValue.code;
      changeLocale(newValue.code);
    }
  };

  const defaultLanguage = languages.find((lang) => lang.code === currentLocale); // Using currentLocale
  const theme = useTheme();

  return (
    <Autocomplete
      options={languages}
      getOptionLabel={(option) => option.name}
      style={{ width: 300 }}
      value={defaultLanguage}
      disableClearable
      onChange={(event, newValue) => {
        handleChangeLocale(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={intl.formatMessage({ id: "Language" })} fullWidth />
      )}
    />
  );
}
