import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export default function DatePickerValue({ formik, localeFormat }) {
  const defaultValue = formik.values.date || '';

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={localeFormat("Date")}
        value={defaultValue}
        format={localeFormat('dateFormat')}
        onChange={(event, value) => {
          formik.setFieldValue('date', value);
        }}
      />
    </LocalizationProvider>
  );
}