import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { BASE_PATH } from 'config';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AdminGuard = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user !== null) {
      if (user.role !== 'admin') {
        navigate(BASE_PATH, { replace: true });
      }
    }
  }, [user, navigate]);

  return children;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
