import React from 'react';

function Download() {
  const handleDownloadClick = async () => {
    try {
      const url = `${process.env.REACT_APP_URL}/export-jobs`; // Replace with the correct endpoint URL
      const response = await fetch(url);

      if (response.ok) {
        const blob = await response.blob();
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'output.csv'; // Specify the desired filename for the download
        downloadLink.click();
      } else {
        console.error('Failed to fetch CSV data');
      }
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  }

  return (
    <div className="App">
      <header className="App-header">
      </header>
      <button onClick={handleDownloadClick}>Download CSV</button>
    </div>
  );
}

export default Download;
