import React from 'react';
// import './App.css';
import StopwatchForm from './StopwatchForm';
import Header from './header';

function Home() {


  return (
    <div className="App">
      <Header />
      <StopwatchForm />
    </div>
  );
}

export default Home;