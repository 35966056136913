import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import dayjs from 'dayjs';

const Timer = ({ localeFormat, isRunning, isStopped, sendValuesToParent, formik, elapsedTime, currentPrice, setElapsedTime, setCurrentPrice, isEditing }) => {

  const calculatePrice = (elapsedTime) => {
    // Calculate current price based on activity and price_id
    // Update the currentPrice state here
    const activityPriceId = 2; // Replace with the actual logic to get price_id
    if (activityPriceId === 2) {
      const hourlyPrice = 35; // Replace with the actual hourly price
      const hours = elapsedTime / 3600;
      const price = hourlyPrice * hours;
      // example: hours: 60/3600 = 0.01667
      // price: 0.01667 * 35 = 0.58
      setCurrentPrice(price.toFixed(2));
      formik.setFieldValue('price', price.toFixed(2));
    } else if (activityPriceId === 4) {
      const constantPrice = 0.29; // Replace with the actual constant price
      setCurrentPrice(constantPrice.toFixed(2));
      formik.setFieldValue('price', constantPrice.toFixed(2));
    }
  }

  var interval;
  var startTime;

  function startStopwatch() {
    // Clear any existing interval
    clearInterval(interval);

    // Set the start time to the current time
    startTime = new Date().getTime();

    // Update the stopwatch every 1 second
    interval = setInterval(updateStopwatch, 1000);
  }

  // Function to stop the stopwatch
  function stopStopwatch() {
    // Clear the interval
    clearInterval(interval);
  }

  function updateStopwatch() {
    // Get the current time
    var now = new Date().getTime();

    // Find the elapsed time since the start
    var elapsedTime = now - startTime;

    var seconds = Math.floor(elapsedTime / 1000);
    // formik.setFieldValue('time_cost_minutes', seconds);
    setElapsedTime(seconds);
    // console.log(seconds)
    calculatePrice(seconds)
    // console.log(elapsedTime)
    // // Time calculations for hours, minutes, and seconds
    // var hours = Math.floor(elapsedTime / (1000 * 60 * 60));
    // var minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
    // var seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

    // // Display the result in the element with id="stopwatch"
    // document.getElementById("stopwatch").innerHTML = hours + "h "
    //   + minutes + "m " + seconds + "s ";
  }

  useEffect(() => {
    if (isEditing) {
      return;
    }
    // console.log(elapsedTime)
    // console.log(isEditing)
    let timerInterval;
    let timerInterval2;

    if (isRunning) {
      startStopwatch()
      // timerInterval = setInterval(() => {
      //   const currentTime = dayjs(); // Current time
      //   const startTime = dayjs(formik.values.start_time);
      //   const elapsedTime = currentTime.diff(startTime, 'seconds'); // Difference in seconds

      //   setElapsedTime(elapsedTime);
      //   formik.setFieldValue('time_cost_minutes', elapsedTime);
      // }, 1000);
      // timerInterval2 = setInterval(() => {
      //   calculatePrice(elapsedTime)
      // }, 1000);
    } else {
      stopStopwatch();
      // console.log('test')
      // clearInterval(timerInterval);
      // clearInterval(timerInterval2);
    }
    if (isStopped) {
      sendValuesToParent(elapsedTime, currentPrice);
    }

    return () => {
      // clearInterval(timerInterval);
      // clearInterval(timerInterval2);
      stopStopwatch();
    }
  }, [isRunning]);
  //removed elapsedTime here, because it started to duplicate instances, 2x, 4x etc
  //TODO: maybe check for editing too

  //if is editing
  useEffect(() => {
    // console.log(formik.values.start_time)
    // console.log(formik.values.end_time)
    // console.log(elapsedTime)
    if (!isEditing) {
      return;
    }
    if (formik.values.start_time && formik.values.end_time) {
      const startTime = dayjs(formik.values.start_time);
      const endTime = dayjs(formik.values.end_time);
      const elapsedTimeInSeconds = endTime.diff(startTime, 'second');
      console.log(elapsedTimeInSeconds)
      setElapsedTime(elapsedTimeInSeconds);
      // formik.setFieldValue('time_cost_minutes', elapsedTime);
      calculatePrice(elapsedTimeInSeconds)
      sendValuesToParent(elapsedTimeInSeconds, currentPrice);
    }

  }, [isEditing, formik.values.start_time, formik.values.end_time, elapsedTime]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    return formattedTime;
  };

  return (
    <>
      <Grid item xs={12} md={2}>
        <TextField id="outlined-basic" label={localeFormat("Time spent")} variant="outlined" placeholder={localeFormat("Time spent")} value={formatTime(elapsedTime)} readOnly />
      </Grid>
      <Grid item xs={12} md={2}>

        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">{localeFormat("Cost")}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
            label={localeFormat("Current cost")}
            value={currentPrice}
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default Timer;
