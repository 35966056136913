import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AlertDialog = ({ open, setDialogOpen, content, title, handleConfirm, localeFormat }) => {

  const handleClose = (result) => {
    console.log(result)
    handleConfirm(result)
    setDialogOpen(false)
  };

  return (
    <Dialog
      open={open}
      onClose={e => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={e => handleClose(false)}>{localeFormat("Cancel")}</Button>
        <Button onClick={e => handleClose(true)} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
