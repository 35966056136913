import * as React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function BasicTimePicker({ formik, label, value, handleTimeChange }) {
  let defaultValue = null;
  if (value !== null) {
    defaultValue = dayjs(value);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={label}
        value={defaultValue}
        onChange={(newValue) => handleTimeChange(newValue)}
      />
    </LocalizationProvider>
  );
}