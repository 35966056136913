import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import Timer from './Timer';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import AlertDialog from './AlertDialog';
import DataTable from './DataTable';
import Button from '@mui/material/Button';
import { Grid, Autocomplete, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocale } from './contexts/LocaleContext';
import { useIntl } from 'react-intl'; // Import the useIntl hook
import useAuth from './hooks/useAuth';

const ClientDatalist = ({ clients, formik, handleStart, localeFormat }) => {
  return (
    <Autocomplete
      id="clients"
      name="client_id"
      // freeSolo
      // onInputChange={(event, value) => {
      //   console.log(value)
      //   formik.setFieldValue('client_id', value);
      // }}
      onChange={(event, value) => {
        console.log(value)
        formik.setFieldValue('client_id', value);
        if (value != null) {
          console.log(value)
          handleStart();
        }
      }}
      value={formik.values.client_id}
      options={clients.map((option) => option.client_name)}
      renderInput={(params) => <TextField {...params} label={localeFormat("Choose or type a client")} />}
    />
  );
};

const ActivityDatalist = ({ activities, formik, localeFormat }) => {
  return (
    <Autocomplete
      id="activity"
      name="activity_id"
      // freeSolo
      // onInputChange={(event, value) => {
      //   console.log(value)
      //   formik.setFieldValue('activity_id', value);
      // }}
      onChange={(event, value) => {
        console.log(value)
        formik.setFieldValue('activity_id', value);
      }}
      value={formik.values.activity_id}
      options={activities.map((option) => option.activity)}
      renderInput={(params) => <TextField {...params} label={localeFormat("Choose or type an activity")} />}
    />
  );
};

const StopwatchForm = () => {
  const dialogRef = useRef();
  const { locale } = useLocale();
  const { getToken } = useAuth();

  const intl = useIntl();

  function localeFormat(id) {
    return intl.formatMessage({ id });
  }

  React.useEffect(() => {
    console.log(locale)
  }, [locale])

  const [currentPrice, setCurrentPrice] = useState(0);
  const [activities, setActivities] = useState([]);
  const [clients, setClients] = useState([]);
  const [jobs, setJobs] = useState([]);

  const [employee, setEmployee] = useState('Gunnar Michelson'); // Replace with the logged-in user's name
  const [isRunning, setIsRunning] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isEditing, setIsEditing] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogType, setDialogType] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [row, setRow] = useState([]);

  const initialValues = {
    client_id: null,
    activity_id: null,
    date: dayjs(),
    start_time: null,
    end_time: null,
    price: null,
    time_cost_minutes: null,
    quantity: null,
    amount: null,
  }

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema,
    onSubmit: async (data, actions) => {
      console.log(data)
      await checkData(data);
      try {
        let newData = await handleStop(data);
        console.log(newData)
        //todo: do something here
        let result = await postData(newData);
        if (result == true) {
          await fetchJobs()
          actions.resetForm(initialValues);
          clearData();
          if (isEditing == true) {
            setIsEditing(false);
          }
        } else {
          handleErrorDialog();
        }
      } catch (error) {
        // Handle errors here
        console.error('Error:', error);
      }
    }
  });

  const handleStopDialog = () => {
    // Call the handleClickOpen function from AlertDialog component
    // to open the dialog
    const title = localeFormat("Missing fields");
    const content = localeFormat("Please make sure all fields are filled before finishing a task");
    setDialogTitle(title);
    setDialogContent(content);
    setDialogOpen(true);
    setDialogType('stop')
  };

  const handleErrorDialog = () => {
    // Call the handleClickOpen function from AlertDialog component
    // to open the dialog
    const title = localeFormat("Error occured");
    const content = localeFormat("There is an error saving the data.");
    setDialogTitle(title);
    setDialogContent(content);
    setDialogOpen(true);
    setDialogType('error')
  };

  const handleCancelDialog = () => {
    // Call the handleClickOpen function from AlertDialog component
    // to open the dialog
    const title = localeFormat("Canceling ongoing entry");
    const content = localeFormat("Are you sure you want to cancel the ongoing entry?");
    setDialogTitle(title);
    setDialogContent(content);
    setDialogOpen(true);
    setDialogType('cancel')
  };

  async function checkData(data) {
    if (data.activity_id == null || data.client_id == null) {
      // alert
      handleStopDialog();
    }
    return true;
  }

  async function fetchActivities() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/activities`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const result = await fetch(url, {
        method: 'GET',
        headers
      });
      if (result.ok) {
        const data = await result.json();
        setActivities(data);
      }
    } catch (error) {
      console.error(error);
      const data = [
        {
          "id": 1,
          "activity": "Aruanne omanikele",
          "group_name": "Aruanded",
          "price_id": 2
        },
        {
          "id": 2,
          "activity": "Bilansi koostamine",
          "group_name": "Aruanded",
          "price_id": 2
        },
      ];
      setActivities(data);
    }
  }
  async function fetchClients() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/clients`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        setClients(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchJobs() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/jobs`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        setJobs(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Fetch all tables data
  useEffect(() => {
    fetchActivities();
    fetchClients();
    fetchJobs();
  }, []);

  // Create a new job
  const postData = async (newJob) => {
    console.log(newJob)
    let url = `${process.env.REACT_APP_URL}/api/job`;
    let method = 'POST';
    if (isEditing == true) {
      method = 'PUT';
      url = `${process.env.REACT_APP_URL}/api/job/${row?.id}`;
    }
    try {
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: method,
        headers,
        body: JSON.stringify(newJob),
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData)
        // Check if the response data contains a 'success' property
        // if (responseData.success) {
        // Job created successfully
        return true;
        // } else {
        //   // Job creation failed
        //   return false;
        // }
      } else {
        // Handle non-200 status codes here
        return false;
      }
    } catch (error) {
      console.error(error);
      // Return an error message or response data in case of an exception
      return false;
    }
  };

  const clearData = async () => {
    formik.setValues(initialValues);
    // formik.setFieldValue('client_id', null);
    // formik.setFieldValue('activity_id', null);
    setElapsedTime(0)
    setCurrentPrice(0)
  }

  const handleStart = () => {
    setIsRunning(true);
    if (formik.values.start_time == null) {
      setStartTime(dayjs())
    }
  }

  const handleStop = async (data) => {
    console.log('stop')
    setIsRunning(false);
    setIsStopped(true);
    if (!isEditing) {
      setEndTime(dayjs())
      data.end_time = dayjs();
    }
    // data.price = currentPrice;
    data.time_cost_minutes = elapsedTime;
    return data;

  };

  const sendValuesToParent = (time, price) => {
    // console.log(time)
    setElapsedTime(time)
    setCurrentPrice(price)
  }

  const setStartTime = (value) => {
    console.log(value)
    formik.setFieldValue('start_time', value);
  }

  const setEndTime = (value) => {
    console.log(value)
    formik.setFieldValue('end_time', value);
  }

  const ifIsRunningOrEditing = () => {
    const title = localeFormat("Running task");
    setDialogTitle(title);
    const content = localeFormat("You are currently working on a task, do you want to cancel that? Unsaved information will be lost");
    setDialogContent(content);
    setDialogOpen(true);
  }


  const editItem = async (row) => {
    console.log(row)
    setDialogType('edit')
    setRow(row)
    if (isRunning || isEditing) {
      ifIsRunningOrEditing();
      return;
    }
    // we need to set this so that we can show save instead of stop for finishing the task
    // and to calculate the values based on time changes
    setIsEditing(true);
    editExistingItem(row);
  }

  const againItem = async (row) => {
    console.log(row)
    setDialogType('again')
    setRow(row)
    if (isRunning || isEditing) {
      ifIsRunningOrEditing();
      return;
    }
    // now we set this true so that the timer starts ticking
    setIsRunning(true);
    newItem(row);
  }

  const editExistingItem = async (row) => {
    const existingValues = {
      client_id: row.client_id,
      activity_id: row.activity_id,
      date: dayjs(row.date),
      start_time: row.start_time,
      end_time: row.end_time,
    }
    formik.setValues(existingValues);
    setCurrentPrice(row.price)
    console.log(row.price)
    // formik.setFieldValue('time_cost_minutes', row.time_cost_minutes);
    console.log(row.time_cost_minutes)
    console.log(elapsedTime)
    setElapsedTime(row.time_cost_minutes)
    console.log(row.time_cost_minutes)
  }

  const newItem = async (row) => {
    const existingValues = {
      client_id: row.client_id,
      activity_id: row.activity_id,
      date: dayjs(),
      start_time: dayjs(),
    }
    formik.setValues(existingValues);
  }

  const handleConfirm = (result) => {
    if (dialogType === 'error') {
      return;
    }
    // in here filter if it was edit or again
    console.log(result)
    // clear data
    // on cancel
    // on edit and existing thing is runnign
    // on again same
    // not on checking if clicking stop has empty fields which is the if right below
    if (dialogType == 'stop') {
      return;
    }
    if (result == true) {
      setIsRunning(false);
      setIsEditing(false);
      clearData();
    }
    if (dialogType == 'edit') {
      setIsEditing(true);
      editExistingItem(row);
    } else if (dialogType == 'again') {
      setIsRunning(true);
      newItem(row);
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} p={5}>
          <Grid item xs={12} md={2}>
            <ClientDatalist clients={clients} formik={formik} handleStart={handleStart} localeFormat={localeFormat} />
          </Grid>
          <Grid item xs={12} md={2}>
            <ActivityDatalist activities={activities} formik={formik} localeFormat={localeFormat} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                {/* <input type="text" placeholder="Date" value={date} onChange={(e) => setDate(e.target.value)} /> */}
                {/* <DatePicker formik={formik} /> */}
                <DatePicker formik={formik} localeFormat={localeFormat} />
              </Grid>
              <Grid item xs={12} md={2}>
                <TimePicker formik={formik} label={localeFormat("Start time")} value={formik.values.start_time} handleTimeChange={setStartTime} />
                {/* <input type="text" placeholder="Start time" value={startTime} /> */}
              </Grid>
              <Grid item xs={12} md={2}>
                <TimePicker formik={formik} label={localeFormat("End time")} value={formik.values.end_time} handleTimeChange={setEndTime} />
                {/* <input type="text" placeholder="End time" value={endTime} onChange={(e) => setEndTime(e.target.value)} /> */}
              </Grid>

              <Timer localeFormat={localeFormat} elapsedTime={elapsedTime} currentPrice={currentPrice} setCurrentPrice={setCurrentPrice} setElapsedTime={setElapsedTime} isRunning={isRunning} isStopped={isStopped} sendValuesToParent={sendValuesToParent} formik={formik} isEditing={isEditing} />

              <Grid item xs={12} md={1}>
                <Button fullWidth type="submit" variant="contained" sx={{ height: '100%' }}
                  disabled={!isRunning && !isEditing}>
                  {!isEditing ? localeFormat('Stop') : localeFormat('Save')}
                </Button>
              </Grid >
              <Grid item xs={12} md={1}>
                <Button fullWidth onClick={e => handleCancelDialog()} variant="contained" sx={{ height: '100%', width: '120%' }} disabled={!isRunning && !isEditing}>
                  {localeFormat("Cancel")}
                </Button>
              </Grid >
              <input type="hidden" placeholder={localeFormat("Employee")} value={employee} />
            </Grid >
          </Grid >
        </Grid >
      </form >
      <Grid container>
        <Grid item xs={12}>
          <DataTable data={jobs} againItem={againItem} editItem={editItem} localeFormat={localeFormat} />
        </Grid>
      </Grid>
      <AlertDialog
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleConfirm={handleConfirm}
        title={dialogTitle}
        content={dialogContent}
        localeFormat={localeFormat}
      />
    </>
  );
};

export default StopwatchForm;
