import * as React from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';

const DataTable = ({ data, editItem = null, againItem = null, localeFormat }) => {

  const duration = require('dayjs/plugin/duration')
  dayjs.extend(duration);

  const getDuration = (seconds) => {
    const duration = dayjs.duration(seconds, 'seconds');
    // Format the duration as a time string
    const formattedTime = `${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`;
    return formattedTime;
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'client_id', headerName: localeFormat('Client'), width: 150 },
    { field: 'activity_id', headerName: localeFormat('Activity'), width: 150 },
    {
      field: 'date', headerName: localeFormat('Date'), width: 110,
      valueGetter: (params) =>
        `${dayjs(params.row.date).format(localeFormat('dateFormat')) || ''}`,
    },
    {
      field: 'start_time',
      headerName: localeFormat('Start time'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        `${dayjs(params.row.start_time).format('HH:mm:ss') || ''}`,
    },
    {
      field: 'end_time',
      headerName: localeFormat('End time'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        `${dayjs(params.row.end_time).format('HH:mm:ss') || ''}`,
    },
    {
      field: 'time_cost_minutes',
      headerName: localeFormat('Time spent'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        getDuration(params.row.time_cost_minutes),
    },
    {
      field: 'price',
      headerName: localeFormat('Cost (€)'),
      type: 'number',
      width: 90,
    },
    {
      field: 'edit',
      headerName: localeFormat('Edit'),
      type: 'actions',
      getActions: (params) => [
        editItem !== null ? (
          < GridActionsCellItem icon={< EditIcon />} onClick={() => editItem(params.row)} label="Edit" />
        ) : <></>,
      ],
      width: 70,
    },
    {
      field: 'again',
      headerName: localeFormat('Again'),
      type: 'actions',
      getActions: (params) => [
        againItem !== null ? (
          <GridActionsCellItem icon={<FileCopyIcon />} onClick={() => againItem(params.row)} label="Again" />
        ) : <></>,
      ],
      width: 70,
    },
  ];

  return (
    <div style={{ height: 'auto', width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 25, 100]}
      // checkboxSelection
      />
    </div>
  );
}

export default DataTable;